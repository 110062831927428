import { reactive, Ref } from "vue";
import { isFunction } from "lodash";

export type FieldsType = {
    [key: string]: {
        key: string,
        modelValue: string,
        rules: {
            [key: string]: (value: string) => Promise<boolean>
        }
    }
}

type ErrorsType = {
    [key: string]: {
        $message: string
    }[]
}

export const rules = reactive({
    required: async (value: string | number | null) => {
        if (!value || (typeof value === 'string' && value.trim())) {
            throw 'Поле не заполнено';
        }
        return true;
    }
});

export function useValidate() {
    const errors: Ref = ref({})

    async function validate(fields: FieldsType): Promise<ErrorsType> {
        const validationPromises = Object.values(fields).map(async (field) => {
            if (field.rules) {
                for (const rule of Object.keys(field.rules)) {
                    try {
                        console.log(field.rules.hasOwnProperty(rule), isFunction(field.rules[rule]));
                        if(field.rules.hasOwnProperty(rule) && isFunction(field.rules[rule])) {
                            await field.rules[rule](field.modelValue);
                            delete errors.value[field.key];
                        }
                    } catch (e) {
                        errors.value[field.key] = [{ $message: e }];
                        break;
                    }
                }
            }
        });

        await Promise.all(validationPromises);

        return errors.value;
    }

    return {
        rules,
        validate,
        errors
    }
}