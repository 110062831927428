<script setup lang="ts">
import AppInput from "@/components/UI/AppInput/AppInput.vue";
import AppSelect from "@/components/UI/AppSelect/AppSelect.vue";
import AppInputAmount from "@/components/UI/AppInputAmount/AppInputAmount.vue";
import AppDateTimePicker from "@/components/UI/AppDateTimePicker/AppDateTimePicker.vue";
import mtRadioGroup from "@/components/UI/mtRadio/mtRadioGroup.vue";
import MtButton from "@/components/UI/mtButton/index.vue";
import useToast from "@/components/UI/AppToast/useToast";
import AppAlert from "@/components/UI/AppAlert/AppAlert.vue";
import { FieldsType, useValidate } from "@/services/validate.ts";

interface Props {
  modelValue: Object;
  handler: () => Promise<any>;
  handlerButtonText?: string;
  disableButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  handlerButtonText: 'Сохранить'
})
const emits = defineEmits(['update:modelValue', 'submit']);

const { validate, errors } = useValidate();

const computedModelValue = computed({
  get() {
    const fields = props.modelValue;
    Object.values(fields)
        .forEach(field => {
          const componentType = {
            string: {
              component: markRaw(AppInput)
            },
            integer: {
              component: markRaw(AppInputAmount)
            },
            date: {
              component: markRaw(AppDateTimePicker),
              bind: {
                format: "dd.MM.yyyy"
              }
            },
            list: {
              component: markRaw(AppSelect),
            },
            radio: {
              component: markRaw(mtRadioGroup),
              bind: {
                inline: true,
              }
            },
          }
          const type = field.type ?? 'string';
          const component = componentType[type];
          field.component = component.component;
          field.bind = {
            ...field.bind,
            ...component.bind,
          }
        })

    return fields;
  },
  set(newValue) {
    emits('update:modelValue', newValue)
  },
})

const requestPending = ref(false);

async function submit() {
  await validate((computedModelValue.value) as FieldsType);

  if (Object.keys(errors.value).length) {
    const { toast } = useToast();

    toast.error('Форма не прошла валидацию, пожалуйста, проверьте введенные данные')
    return;
  }

  requestPending.value = true;

  props.handler()
      .finally(() => {
        requestPending.value = false;
      })

}

</script>

<template>
  <div class="app-form-builder">
    <slot name="prepend-form"/>
    <app-alert type="info" class="mb-4">Поля со звездочкой <sup>(*)</sup> обязательны для заполнения</app-alert>
    <div class="app-form-builder__fields">
      <template v-for="field in computedModelValue">

        <component
            :is="field.component"
            v-bind="field.bind"
            v-model="field.modelValue"
            :errors="errors[field.key]"
        />
      </template>

      <slot name="prepend-buttons"/>
      <div class="app-form-builder__btn-wrapper">
        <mt-button
            @click="submit"
            :loading="requestPending"
            :disabled="props.disableButton"
            class="app-form-builder__btn"
            size="large"
        >
          {{ props.handlerButtonText }}
        </mt-button>
      </div>
      <slot name="append-buttons"/>


    </div>

  </div>
</template>

<style lang="scss">
.app-form-builder {
  width: 100%;
  &__fields {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__btn-wrapper {
    margin-top: 20px;
    width: 100%;
  }
}
</style>